import React, { useEffect, useState } from "react"
import { useSearchContext } from "./SearchContextProvider"
import { Link } from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-regular-svg-icons"
import { faStar as faStarFull } from "@fortawesome/free-solid-svg-icons"
import { Action } from "../utils/model"
import classnames from "classnames"

type ActionCardProps = {
  action: Action,
  enableSelect: boolean
}

const ActionCard = ({action, enableSelect}: ActionCardProps) => { 

  const { actions: { selectAction, unselectAction }, filters: { actions } } = useSearchContext();
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    setSelected(actions.includes(action.externalID))
  }, [actions, action]);

  return (

    <div key={action.id} className="relative bg-white shadow-md rounded-md py-3 px-4 max-h-60 transition duration-500 ease transition-shadow hover:shadow-2xl" >
      {enableSelect && <div className="flex-grow text-right absolute top-0 right-0 pr-3 pt-2 border-b border-l rounded-bl"
        style={{width:"40px", height:"40px"}} 
        onClick={() => selected ? unselectAction(action.externalID) : selectAction(action.externalID)}
        >
        <FontAwesomeIcon
          icon={selected ? faStarFull : faStar}
          className={classnames("cursor-pointer text-base text-gray-400", selected ? "text-secondary" : "text-gray-400")}
          />
      </div>}
      <Link to={action.slug} className="flex flex-col h-full">
        <div className="flex flex-row mb-2">
          <img className="self-center mr-1" style={{ width: "30px", height: "30px" }} src={action.theme.pictoURL} />

          <span className="self-center text-xs font-extralight text-primary">{action.theme.name}</span>
          
        </div>
        <div className="text-lg font-bold text-primary flex-grow">
          {action.name}
          <span className="text-gray-600 font-light text-sm ml-2">#{action.externalID}</span>
        </div>
        <div className="my-2 flex flex-row ">
          {action.domains?.map(domain => 
            <span key={domain.id} className="text-xs py-1 px-2 bg-tag bg-opacity-40 rounded text-primary mr-1" >{domain.name}</span>
          )}
        </div>
      </Link>
    </div>
  )
}

export default ActionCard