import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Theme } from "../utils/model"
import { useSearchContext, FilterType } from "./SearchContextProvider"
import Item  from "./Item"

const Themes = () => {

  const data = useStaticQuery(graphql`
    query ThemesQuery {
      themes: allAirtable(filter: {table: {eq: "Theme"}, data: {Actions: {ne: null}}}) {
        nodes {
          id
          data {
            Name
            Order
          }
        }
      }
    }
  `)

  const themes = data.themes.nodes.map(raw => new Theme(raw))

  const { actions: { setFilter }, filters: { filtertype, filtervalue } } = useSearchContext();

  return (

    <div className="text-white relative ml-2 mt-2">
      <div className="text-white font-thin text-xs tracking-wider ml-4">OU PAR :</div>
      <div className="text-base text-secondary font-bold mb-2 whitespace-no-wrap ml-4">thématique</div>
      <ul>
        {themes.sort((a, b) => a.order - b.order).map(theme => ( 
          <Item key={theme.id} object={theme} targetFilterType={FilterType.theme} filtertype={filtertype} filtervalue={filtervalue} 
            handler={() => { setFilter(FilterType.theme, theme.id) }}/>
        ))}
      </ul>
    </div>
  )
}

export default Themes
