import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Domain } from "../utils/model"
import { useSearchContext, FilterType } from "./SearchContextProvider"
import Item  from "./Item"

const Domains = () => {

  const data = useStaticQuery(graphql`
    query DomainsQuery {
      domains: allAirtable(filter: { table: { eq: "Domain" }}) {
        nodes {
          id
          data {
            Name
            Order
          }
        }
      }
    }
  `)

  const domains = data.domains.nodes.map(raw => new Domain(raw))

  const { actions: { setFilter }, filters: { filtertype, filtervalue } } = useSearchContext();

  return (
    <div className="bg-primary text-white relative pl-2 py-2">
      <div className="text-white font-thin text-xs tracking-wider ml-4">FILTRER PAR :</div>
      <div className="text-base text-secondary font-bold mb-2 whitespace-no-wrap ml-4">domaine d'action</div>
      <ul className="">
        {domains.sort((a, b) => a.order - b.order).map(domain => (
          <Item key={domain.id} object={domain} targetFilterType={FilterType.domain} filtertype={filtertype} filtervalue={filtervalue} 
            handler={() => { setFilter(FilterType.domain, domain.id) }}/>
        ))}
      </ul>
    </div>
  )
}

export default Domains
