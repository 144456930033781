import React, { useEffect, useState } from "react"
import classnames from "classnames"
import Bullet from "../images/bullet.svg"
import BulletSelected from "../images/bulletselected.svg"
import { Theme, Domain } from "../utils/model"
import { FilterType } from "./SearchContextProvider"

type ItemProps = {
  object: Theme | Domain,
  targetFilterType: FilterType,
  filtertype: FilterType,
  filtervalue: string,
  handler: () => void
}

const Item = ({ object, targetFilterType, filtertype, filtervalue, handler }: ItemProps) => {

  const [selected, setSelected] = useState(false)

  useEffect(() => {
    setSelected(filtertype == targetFilterType && filtervalue == object.id)
  }, [filtertype, filtervalue, targetFilterType, object]);

  return (
    <li
      key={object.id}
      onClick={() => handler()}
      className={classnames(
        "text-sm font-extralight py-2 mb-1 px-4 mr-1 rounded-md hover:bg-opacity-50 hover:bg-gray-400 flex flex-row mr-3 hover:text-white ",
        selected ? "bg-opacity-50 bg-gray-400 text-white" : "text-filter"
      )}
      >

      <img
        src={selected ? BulletSelected : Bullet}
        className={selected ? "mr-3" : "ml-1 mr-4"}
      />
      <span>{object.name}</span>
    </li>
  )
}

export default Item
