import React from "react"
import { useSearchContext, FilterType } from "./SearchContextProvider"
import Item  from "./Item"

const ClearFilter = () => {

  const { actions: { setFilter }, filters: { filtertype, filtervalue } } = useSearchContext();

  return (

    <div className="text-white bg-primary relative pl-2 pt-2 pt-4 pb-3" 
      style={{boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.15)", borderBottom: "1px solid #2A4E7F"}}>
      <Item object={{id:"0", name:"Tout afficher", order:1}} targetFilterType={FilterType.none} filtertype={filtertype} filtervalue={filtervalue} 
            handler={() => { setFilter(FilterType.none, "0") }}/>       
    </div>
  )
}

export default ClearFilter
