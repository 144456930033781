import React, { useState } from "react"
import Layout from "../components/layout"
import Themes from "../components/Themes"
import Domains from "../components/Domains"
import ClearFilter from "../components/ClearFilter"
import ActionList from "../components/ActionList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSlidersH, faTimes } from "@fortawesome/free-solid-svg-icons"
import classnames from "classnames"

export default function BrowsePage(props) {

  const [showFilter, setShowFilter] = useState(false)

  const toggleFilter = () => setShowFilter(!showFilter)

  return (
    <Layout>
      <div className={classnames(showFilter ? "absolute": "hidden sm:block", "z-50  top-0 h-full w-full overflow-y-auto search-nav sm:fixed sm:pt-20 sm:z-20 sm:w-1/4 bg-primarylight flex flex-col font-sans")}>
        <div className="sm:hidden text-white bg-primary relative  pl-6 pr-2 pt-2 font-bold	flex">
          Filtrer les actions
          <div className="flex-grow text-right">
            <FontAwesomeIcon
                  icon={faTimes}
                  className="cursor-pointer mr-3 font-light"
                  onClick={() => toggleFilter()}
            />
          </div>
        </div>
        <ClearFilter />
        <Domains /> 
        <Themes />
      </div>
      <div className="fixed z-30 w-full sm:hidden" onClick={() => toggleFilter()}>
        <div className="bg-primary p-4 text-white font-bold font-sans rounded mx-4">
          <FontAwesomeIcon
                icon={faSlidersH}
                className="text-secondary cursor-pointer mr-3"
          />
          Filtrer les actions
        </div>
      </div>
      <ActionList/> 
    </Layout>
  )
}
