import React from "react"
import { useSearchContext, filterAction } from "./SearchContextProvider"
import ActionCard from "./ActionCard"

const ActionList = () => {

  const { filters: { filtertype, filtervalue, all } } = useSearchContext();
  let filteredActions = filterAction(all, filtertype, filtervalue);

  return (
    <div className="w-full flex justify-end">
      <div className="w-full grid grid-cols-1 gap-6 p-2 auto-rows-min font-sans sm:grid-cols-2 pt-16 sm:pt-10 sm:w-3/4 sm:px-10">
        {
          filteredActions?.map(action => (
              <ActionCard key={action.id} action={action} enableSelect={true} />
            ))
        }
      </div>
    </div>
  )
}

export default ActionList
